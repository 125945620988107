import { formatter } from './format'

export const formatForm = (
  form: Record<string, unknown>,
): Record<string, unknown> => {
  const result: Record<string, unknown> = {}

  Object.keys(form).forEach((key) => {
    const value = form[key]
    if (value instanceof Date) {
      const date = form[key] as Date
      result[key] = formatter.date(date, 'filter')
      return
    }
    if (Array.isArray(value) && value.length > 0) {
      if (key === 'source_arr') {
        result.source = value
          .map((optionValue: string) => {
            if (
              optionValue === 'other' &&
              typeof form.source_other === 'string' &&
              form.source_other.trim().length > 0
            ) {
              return `other: ${form.source_other.trim()}`
            }
            return optionValue
          })
          .join(', ')
      } else {
        result[key] = value.join(', ')
      }
      return
    }

    if (Array.isArray(value) && value.length <= 0) {
      return
    }

    if (value || value === 0 || value === false) {
      result[key] = value
    }
  })

  return result
}
